<template>
  <!-- 预约记录 -->
  <div class="appointment-record">
    <Scroll
      class="region-scroll"
      ref="scroll"
      @pullingUp="scrollBottom"
      :pullUpLoad="true"
    >
      <!-- 切换就诊人 -->
      <SwitchPeople />
      <div class="line"></div>
      <van-list
        v-model="loading"
        :finished="finished"
        :error.sync="error"
        @load="onLoad"
        error-text="请求失败，点击重新加载"
        finished-text="没有更多了"
      >
        <!-- 空状态 -->
        <div v-if="appointmentList.list.length == 0">
          <van-empty description="无预约记录" />
        </div>
        <!-- 预约信息 -->
        <div
          v-else
          class="appointment-list"
          v-for="item in appointmentList.list"
          :key="item.id"
          @click="query(item)"
        >
          <div class="arrow">
            <svg
              class="icon"
              style="font-size:1.4rem"
              aria-hidden="true"
            >
              <use xlink:href='#icon-youjiantou1-copy'></use>
            </svg>
          </div>
          <div>
            <span>科室:</span>
            <span>{{item.departMent}}</span>
          </div>
          <div>
            <span>医生:</span>
            <span>{{item.doctor}}</span>
          </div>
          <div>
            <span>就诊时间:</span>
            <span>{{item.shift}}</span>
          </div>
          <div>
            <span>预约状态:</span>
            <span>{{item.orderFlag == 0 ? "成功" : "已取消"}}</span>
          </div>
        </div>
      </van-list>
    </Scroll>
  </div>
</template>

<script>
import Scroll from '@/components/content/scroll/BScroll'
import SwitchPeople from '@/components/common/switchPeople/switchPeople'
import { mapState } from 'vuex'

export default {
  name: 'appointmentRecord',
  components: {
    Scroll,
    SwitchPeople
  },
  data () {
    return {
      //等待加载
      loading: false,
      //没有更多了
      finished: false,
      //请求失败，点击重新加载
      error: false,
    }
  },
  computed: {
    ...mapState(['cardList', 'nameState', 'headPortrait', 'valve', 'appointmentList', 'pageSet'])
  },
  mounted () {
    //重置信息
    this.appointmentList.page = 1
    this.appointmentList.list = []
    this.$store.state.valve = true
    //预约信息查询请求
    this.appointmentPost()
  },
  methods: {
    //点击重新加载
    onLoad () {
      this.appointmentPost()
    },
    //预约信息查询请求
    appointmentPost () {
      this.$store.commit('INFORMATION_SERVICE', {
        type: '预约',
        name: 'appointmentList',
        _this: this,
      })
    },
    //下拉加载
    scrollBottom () {
      if (this.valve && this.appointmentList.list.length >= this.pageSet)
        this.appointmentPost()
    },
    //单个预约查询
    query (item) {
      this.$router.push({
        path: '/appointment-cancellation', query: {
          departMent: item.departMent,
          diagnosisTime: item.diagnosisTime,
          doctor: item.doctor,
          shift: item.shift,
          bid: item.bid,
          registerSeq: item.registerSeq,
          flag: item.orderFlag
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
.appointment-record {
  background-color: #f4f4f4;
  height: 100%;
  .region-scroll {
    height: 100vh;
    overflow: hidden;
    .line {
      height: 0.1px;
    }
    .appointment-list {
      position: relative;
      margin-top: 0.5rem;
      background-color: rgba(254, 254, 254);
      line-height: 2rem;
      padding: 0.5rem 0;
      .arrow {
        position: absolute;
        top: 50%;
        right: 5%;
        transform: translateY(-50%);
      }
      div {
        display: flex;
        margin-left: 1rem;
        span:first-child {
          flex: 0.3;
          color: #8d8fa1;
        }
        span {
          flex: 0.6;
        }
      }
    }
  }
}
</style>